import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Image } from 'nordic/image';

import Action from '../action';
import Header from './header';
import { useRenderContext } from '../../pages/home/context';

const CategoryLabelImage = (props) => {
  const {
    styles,
    content,
    i18n = { gettext: f => f },
  } = props;
  const { isEshops, theme } = useRenderContext();

  const {
    behavior,
    label: titleCatgeory,
    link: {
      url: linkCategory,
    },
    price_from: {
      whole: priceCategory,
      currency_symbol: priceSymbol,
    },
    show_title_category: showTitle,
    show_base_price: showPrice,
    show_category_button: showCta,
    category_button_text: cta,
  } = content;

  const {
    background_image: urlImage,
    is_default_image: isDefaultImage,
  } = styles;

  const subtitle = i18n.gettext('desde');
  const subtitleEshops = i18n.gettext('Desde');

  const hasTitleCategory = Boolean(showTitle && titleCatgeory);
  const hasPriceCategory = Boolean(showPrice && priceCategory);
  const hasButtonAction = Boolean(showCta && cta);
  const isPriceInvalid = priceCategory && priceCategory === '0' && isEshops;

  const behaviorClassName = classNames(
    'section-category-item__header',
    {
      'section-category-item__title--hidden': !hasTitleCategory,
      'section-category-item__price--hidden': !hasPriceCategory,
      'section-category-item__button--hidden': !hasButtonAction,
      'section-category-item__price--hidden-eshops': isPriceInvalid,
    },
  );

  const itemClass = classNames('section-category-item',
    {
      [`section-category-item--${behavior}`]: behavior,
    });

  const price = hasPriceCategory ? `${priceSymbol}${priceCategory}` : '';
  const priceEshops = hasPriceCategory ? `${priceSymbol} ${priceCategory}` : '';

  const shopsInfo = {
    price,
    subtitle,
  };

  const eshopsInfo = {
    price: priceEshops,
    subtitle: subtitleEshops,
  };

  const information = isEshops ? eshopsInfo : shopsInfo;

  const imageContainerClass = classNames('section-category-item__image-container',
    {
      'section-category-item__image-default': isDefaultImage || isEshops,
      'section-category-item__image-custom': !isDefaultImage || !isEshops,
    });

  return (
    <Action className={itemClass} target={linkCategory} isSelf={isEshops}>
      <div className="section-category-item__container">
        <div className={imageContainerClass}>
          <Image
            className="section-category-item__image"
            src={urlImage}
            onload="on"
            alt={titleCatgeory}
          />
        </div>
        <div className={behaviorClassName}>
          <Header
            cta={cta}
            price={information.price}
            subtitle={information.subtitle}
            title={titleCatgeory}
            hasButtonAction={hasButtonAction}
            hasPriceCategory={hasPriceCategory}
            hasTitleCategory={hasTitleCategory}
            theme={theme}
            isPriceInvalid={isPriceInvalid}
          />
        </div>
      </div>
    </Action>
  );
};

CategoryLabelImage.propTypes = {
  i18n: PropTypes.shape(),
  styles: PropTypes.shape({
    background_image: PropTypes.string,
    is_default_image: PropTypes.bool,
  }).isRequired,
  content: PropTypes.shape({
    link: PropTypes.shape({
      url: PropTypes.string,
    }).isRequired,
    label: PropTypes.string,
    behavior: PropTypes.oneOf(['show', 'hide', 'hover']),
    price_from: PropTypes.shape({
      whole: PropTypes.string,
      currency_symbol: PropTypes.string,
    }).isRequired,
    show_title_category: PropTypes.bool.isRequired,
    show_base_price: PropTypes.bool.isRequired,
    show_category_button: PropTypes.bool.isRequired,
    category_button_text: PropTypes.string.isRequired,
  }).isRequired,
};

export default CategoryLabelImage;
